<template>
  <div class="content">
    <div class="row ">
      <div class="blue-card text-center " data-aos="fade-up" data-aos-duration="1500">
          <div class="m-4">
        <div class="text-1 Tangier_Black" data-aos="fade-up" data-aos-duration="1500">Qui-suis-je ?</div> <br>
        <div class="text-2 text-center" data-aos="fade-up" data-aos-duration="1500">
          J’ai créé Parfait recrutement après l’obtention de mon diplôme en
          Ressources Humaines. La somme de mes expériences professionnelles
          jusqu’ici m’a toujours conduit à prendre de mes relations
          interpersonnelles.<br>
          C’est ma passion pour le volet social de mon
          travail, les échanges directs et attentifs avec les clients comme les
          candidats, et mon envie de coller au plus près des besoins de mes
          clients qui ont imposé la création d’entreprise comme une évidence.
          J’ai eu envie, en effet, de pousser encore plus loin mes exigences de
          qualité.<br>
          <h2 class="Tangier_Medium mt-4">Ce métier a besoin de personne à l’écoute, qui comprend les
          attentes de ses interlocuteurs et veille au bien-être des
          collaborateurs.</h2>  <br>
          Parfait recrutement a été conçu comme une réponse à
          tous ces défis et permet de prendre en charge l’ensemble des exigences
          du métier : gestion administrative, gestion des carrières et des
          compétences, le recrutement.
        </div></div>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.content-warper {
  min-height: 80%;
  max-width: 1300px;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .blue-card {
    width: 85%;
    margin: auto;
    background-color: #6295fe;
    box-shadow: -8px 8px 6px -5px rgba(0, 0, 0, 0.75);
  }
  .text-2{
    font-family:'Noto Sans';
    font-size: 1.2rem;
    color: white;
  }
}

@media screen and (min-width: 992px) {

  .blue-card {
  margin: 10%;
  background-color: #6295fe;
  box-shadow: -8px 8px 6px -5px rgba(0, 0, 0, 0.75);
  }

  .text-2{
    margin: 30px 130px 60px 130px;
    /* margin-top: 30px;
    margin-left: 130px;
    margin-right: 130px;
    margin-bottom: 60px; */

    font-family:'Noto Sans';
      font-size: 1.2rem;
  color: white;

  }
}

.text-1{
  font-size: 38px;
  font-weight: 500;
  color: white;
}

</style>